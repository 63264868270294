<!--SRM采购管理系统-->
<template>
  <div class="srm-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="SRM采购管理系统" @btnClick="bannerClick"
                  imgStyle="width:499px;height:372px" :imgSrc="`${require('@/assets/img/solution/SRM采购管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--当前企业面临的痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">当前企业面临的痛点</p>
        <div class="module-col">
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="col-content-1">
                <p class="col-title">采购类型多</p>
                <p class="col-content" style="margin-block-end: 40px;">采购制度：采购金额、部门、品类的差异导致管理标准不同。</p>
                <p class="col-content" style="margin-block-end: 70px;">采购流程：从供应商管理、寻源操作到合同签订、订单执行，业务流程长，审批节点多，传统管理透明度低，联动性差。</p>
                <div class="col-img"/>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content-2">
                <p class="col-title">供应商管理难</p>
                <p class="col-content">寻源：源头少，途径缺乏。</p>
                <p class="col-content">核验：身份、资质审查核验难。</p>
                <p class="col-content">管理：数据庞大，证照繁多，风控滞后，预警缺失。</p>
                <p class="col-content">考评：缺乏有效分级体系、评价考核体系。</p>
                <p class="col-content">分析：缺乏供应商全生命周期的闭环管理分析。</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">文档管理难</p>
                <p class="col-content">签署：招投标文件、合同及相关附件、协议、变更等，需要人工打印、签署、邮寄，周期长，效率低。</p>
                <p class="col-content">归档：大量合同文本和技术附件需要复印、扫描，耗时费力，保存难，查询难。</p>
                <p class="col-content">风险：文档签署时缺乏操作者的真实身份认证，用印过程不可知，审计溯源难。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="col-content-2">
                <p class="col-title">内外协同难</p>
                <p class="col-content">内外：采购过程交互频繁，传统邮件、电话、微信等管理效率低，容易出错。</p>
                <p class="col-content">协同： 采、供双方协同角色涉及业、财、法多部门，口径多，流程长；协同内容多属性：业务&事务、结构化（账务）&非机构化（文本）等。</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">成本控制难</p>
                <p class="col-content">比价：买卖信息不透明，无法进行全面的价格比对，对于采购价格缺少执行预测和控制预警，对于交易的合规性缺乏数据支持。</p>
                <p class="col-content">比质：服务类的非标采购缺乏成本评判依据，隐形成本高；内部采购计划性差，安全库存缺失，采购被动比质空间小。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!--SRM系统主要亮点-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">SRM系统主要亮点</p>
        <div class="module-col">
          <div class="module-left">
            <div class="module-li li-1">
              <p class="title">电子化</p>
              <p class="content">供应商身份认证、在线报价评标、采购合同签暑、采购执行协同等全流程线上化,打通全程数字化最后一公里</p>
            </div>
            <div class="module-li li-2">
              <p class="title">内外协同</p>
              <p class="content">打通内外,实现外部供应商准入、寻源、执行、结算全过程协同,内部采购、法务、质量、仓库、财务全角色协作</p>
            </div>
            <div class="module-li li-3">
              <p class="title">卓越性能</p>
              <p class="content">成熟技术能力、完善安全体系、超高并发实证、全栈信创支持,为集团采购业务保驾护航</p>
            </div>
          </div>
          <div class="center-module">
            <div class="module-center"/>
          </div>
          <div class="module-right">
            <div class="module-li li-1">
              <p class="title">智能化</p>
              <p class="content">智能助手、RPA技术深度融合，语音需求发起、智能比价、智能对账、智能风险识别：业务创新，技术赋能</p>
            </div>
            <div class="module-li li-2">
              <p class="title">统一平台</p>
              <p class="content">一套组织、一个窗口、一个平台实现所有采购业务,七大引擎灵活扩展、丰富组件、低代码构建,支撑组织数字化运营</p>
            </div>
            <div class="module-li li-3">
              <p class="title">移动助力</p>
              <p class="content">供应商商机跟踪、合同签订、往来对账；采购准入审批、需求处理、采购执行，一部手机实现采购全业务全场景开展</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--合同管理产品亮点-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="color: #FFFFFF">SRM采购系统核心功能</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--帮企数智云CMS的优势-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云SRM的优势</p>
        <div class="module-col">
          <el-row :gutter="30">
            <template v-for="list of dataList2">
              <el-col :span="8">
                <div class="col-content">
                  <div class="col-img"
                       :style="'background: url('+require('@/assets/img/solution/'+list.icon)+') no-repeat'"/>
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'SRM采购管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>

<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";

export default {
  name: "srm",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerContent:'SRM采购管理系统，1000 +采购功能、20 +行业全场景覆盖，为不同规模企业提供差异化的采购管理系统开发，从需求到寻源，订单到对账开票覆盖整体采购业务，真正做到对采购每个环节进行严密的跟踪与管理，推动采购战略执行，全面提升效率与管理体验。',
      dataList2:[
        {title: '好用适用', introduce: '采购、财务、合规多部门协作，不同品类支持不同流程、显示不同页面，满足多样需求', icon: '好用适用.png'},
        {title: '提效降本', introduce: '流程自动化，摆脱重复事务，采购工具让每一个价格都有据可依', icon: '提效降本.png'},
        {title: '全程可视', introduce: '采购全程记录，运营情况一目了然，智能应用赋能战略决策', icon: '全程可视.png'},
      ]
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>

<style scoped lang="scss">
.srm-container{
  background: #FFFFFF;
  .module-1 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content-1{
          background: #E5E9FF;
          padding: 30px 20px 0px;
          height: 560px;
          .col-img{
            width: 302px;
            height: 195px;
            background: url("~@/assets/img/solution/2023728.png");
            background-size: 100% 100%;
            margin-left: 70px;
          }
        }
        .col-content-2{
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 10px;
          height: 230px;
        }
        .col-title{
          border-left: 4px solid #1935DE;
          padding-left: 10px;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 15px;
        }
        .col-content{
          font-size: 16px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 15px;
        }
      }
    }
  }
  .module-2 {
    background: #F7F8FE;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px 20px;
      .module-col {
        display: flex;
        justify-content: space-between;
        .center-module{
          width: 530px;
          height: 509px;
          .module-center{
            width: 483px;
            height: 435px;
            background: url("~@/assets/img/solution/SRM系统主要亮点.png");
            background-size: 100% 100%;
          }
        }
        .module-left{
          width: 370px;
          margin-top: -10px;
          .module-li {
            text-align: end;
            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }
          .li-1{
            width: 417px;
          }
          .li-2 {
            margin-top: 106px;
            width: 340px;
          }
          .li-3 {
            width: 416px;
            margin-top: 96px;
          }
        }
        .module-right {
          width: 350px;
          margin-top: -10px;
          .module-li {
            text-align: left;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }
          .li-1{
            width: 415px;
            margin-left: -30px;
          }
          .li-2 {
            margin-top: 103px;
            margin-left: 40px;
            width: 345px;
          }
          .li-3 {
            width: 425px;
            margin-top: 90px;
            margin-left: -30px;
          }
        }
      }
    }
  }
  .module-3 {
    height: 724px;
    background: #132483;
    width: 100%;
    padding-bottom: 40px;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-img {
        width: 1200px;
        height: 489px;
        background: url("~@/assets/img/solution/SRM采购系统核心功能.png") no-repeat;
        background-size: 100% 100%;
        float: right;
      }
    }
  }
  .module-4 {
    background: #FFFFFF;
    height: 432px;
    width: 100%;
    padding-bottom: 60px;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .col-content {
        background: #FFFFFF;
        padding: 30px 20px;
        margin-bottom: 15px;
        border: 1px solid #DDDDDD;

        .default-title {
          font-size: 18px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          margin-block-end: 15px;
          margin-block-start: 10px;
        }

        .introduce {
          font-size: 16px;
          font-family: "SourceHanSansCN";
          color: #333333;
          margin-block-end: 0px;
          margin-block-start: 0px;
        }

        .col-img {
          width: 90px;
          height: 85px;
          background-size: 100% 100% !important;
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
